<template>
  <div class="main">
    <div class="title">
      <h2>成员管理</h2>
    </div>
    <ul class="tab">
      <li :class="{current:status===2}"
          @click.stop="onClickTabItem(2)">成员信息
      </li>
      <li :class="{current:status===1}"
          @click.stop="onClickTabItem(1)">待审核成员
      </li>
    </ul>
    <div class="content">
      <div class="topbar">
        <div class="topbar-left">
          <Select @on-change="onSearch"
                  class="m-r-10"
                  clearable
                  placeholder="权限"
                  style="width:120px"
                  v-model="permissionId"
                  v-show="status===2">
            <Option :key="item.id"
                    :value="item.id"
                    v-for="item in permissionArr">{{ item.name }}
            </Option>
          </Select>
          <Input @on-search="onSearch"
                 placeholder="输入名称"
                 search
                 style="width:200px"
                 v-model="name"/>
        </div>
        <div class="topbar-right">
          <div v-show="status===1&&selectArr.length>0">
            <Button @click.stop="batchAudit(1)">
              <Icon custom="i-icon icon-pass"
                    size="16"/>
              通过
            </Button>
            <Button @click.stop="batchAudit(2)"
                    class="m-l-20">
              <Icon custom="i-icon icon-cancle"
                    size="16"/>
              拒绝
            </Button>
          </div>
          <div v-show="status===2">
            <Button @click.stop="batchDelete"
                    v-show="selectArr.length>0">
              <Icon custom="i-icon icon-delete"
                    size="16"/>
              删除
            </Button>
            <Button @click.stop="onClickCreate"
                    class="m-l-20"
                    type="primary">
              <Icon custom="i-icon icon-add"
                    size="16"/>
              添加成员
            </Button>
          </div>
        </div>
      </div>
      <div class="list">
        <div v-show="status===1">
          <Table :columns="auditCoulmns"
                 :data="auditData"
                 :height="tableHeight"
                 @on-selection-change="onSelectionChange"
                 highlight-row
                 key="table1"
                 ref="table1">
            <template slot="operation"
                      slot-scope="{ row,index }">
              <div class="operation">
                <Button @click.stop="onClickAudit(row,1)"
                        class="m-r-10"
                        type="text">
                  <Icon custom="i-icon icon-pass"
                        size="20"/>
                  通过
                </Button>
                <Button @click.stop="onClickAudit(row,2)"
                        type="text">
                  <Icon custom="i-icon icon-cancle"
                        size="20"/>
                  拒绝
                </Button>
              </div>
            </template>
          </Table>
        </div>
        <div v-show="status===2">
          <Table :columns="memberCoulmns"
                 :data="memberData"
                 :height="tableHeight"
                 @on-selection-change="onSelectionChange"
                 highlight-row
                 key="table2"
                 ref="table2">
            <template slot="operation"
                      slot-scope="{ row,index }">
              <div class="operation">
                <Button @click.stop="onClickEdit(row)"
                        class="m-r-10"
                        type="text">
                  <Icon custom="i-icon icon-edit"
                        size="16"/>
                  编辑
                </Button>
                <Button @click.stop="onClickDelete(row)"
                        type="text">
                  <Icon custom="i-icon icon-delete"
                        size="16"/>
                  删除
                </Button>
              </div>
            </template>
          </Table>
        </div>
        <Page :current="pageNum"
              :page-size="pageSize"
              :total="status===1?auditCount:memberCount"
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange"
              class="text-right m-t-10 m-r-20"
              show-elevator
              show-sizer
              show-total/>
      </div>
    </div>
    <Modal :title="memberPopupStatus===1?'添加':'编辑'+'成员'"
           @on-visible-change="onUserAddClose"
           footer-hide
           v-model="createMemberVisible"
           width="680">
      <div class="createMember">
        <ul>
          <li class="createMember-li">
            <span>姓名：</span>
            <Input maxlength="7"
                   placeholder="请输入姓名"
                   style="width:180px"
                   v-model="createMember.name"></Input>
          </li>
          <li class="createMember-li">
            <span>性别：</span>
            <RadioGroup v-model="createMember.sex">
              <Radio :label="1"
                     class="m-r-20">男
              </Radio>
              <Radio :label="2">女</Radio>
            </RadioGroup>
          </li>
          <li class="createMember-li">
            <span>联系电话：</span>
            <Input maxlength="11"
                   placeholder="请输入联系电话"
                   style="width:180px"
                   v-model="createMember.phone"></Input>
          </li>
          <li class="createMember-li">
            <span>部门：</span>
            <Cascader :clearable="false"
                      :data="departmentArr"
                      :load-data="getDepartmentList"
                      :render-format="renderFormat"
                      @on-change="onChangeDepartment"
                      change-on-select
                      ref="cascader"
                      transfer
                      v-model="department"></Cascader>
          </li>
          <li class="createMember-li">
            <span>职位：</span>
            <Select :disabled="!createMember.departmentId"
                    @on-change="onChangePosition"
                    label-in-value
                    placeholder="请选择"
                    style="width:180px"
                    v-model="createMember.positionId">
              <Option :key="item.id"
                      :value="item.id"
                      v-for="item in positionArr">{{ item.name }}
              </Option>
            </Select>
            <span class="m-l-30">角色权限：</span>
            <Select placeholder="请选择"
                    style="width:180px"
                    v-model="createMember.permission">
              <Option :key="item.id"
                      :value="item.id"
                      v-for="item in permissionArr">{{ item.name }}
              </Option>
            </Select>
          </li>
        </ul>
        <div class="createMember-avatar">
          <img :src="createMember.avatar||require('../../assets/common/ic_default_avatar.png')">
          <UploadComponent @uploadSuccess="uploadLogo"
                           uploadpath="/common/logo/">
            <Button>上传头像</Button>
          </UploadComponent>
        </div>
        <div class="createMember-sub">
          <Button @click.stop="onClickCreateConfirm"
                  class="createMember-btn m-r-20"
                  type="primary">确定
          </Button>
          <Button @click.stop="createMemberVisible=false"
                  class="createMember-btn">取消
          </Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  auditMember,
  createMember,
  deleteMember,
  editMember,
  getDepartmentList,
  getMemberDetail,
  getMemberList,
  getPositionList
} from '../../services/enterpriseapi'
import UploadComponent from '../../components/common/UploadComponent'

export default {
  components: {UploadComponent},
  data () {
    return {
      tableHeight: 0,
      auditCount: 0,
      memberCount: 0,
      status: 2,
      name: '',
      permissionId: 0,
      permissionArr: [
        {
          id: 1,
          name: '超级管理员'
        },
        {
          id: 2,
          name: '管理员'
        },
        {
          id: 3,
          name: '普通用户'
        }
      ],
      department: [],
      departmentArr: [],
      positionArr: [],
      auditData: [],
      memberData: [],
      auditCoulmns: [
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '序号',
          width: 120,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '部门',
          key: 'department',
          width: 200,
          tooltip: true,
          align: 'center'
        },
        {
          title: '职位',
          key: 'position',
          width: 200,
          align: 'center'
        },
        {
          title: '姓名',
          key: 'name',
          minWidth: 120,
          align: 'center'
        },
        {
          title: '联系电话',
          key: 'phone',
          width: 260,
          align: 'center'
        },
        {
          title: '注册时间',
          key: 'registerTime',
          width: 260,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 140
        }
      ],
      memberCoulmns: [
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '序号',
          width: 120,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '部门',
          key: 'departmentName',
          width: 200,
          tooltip: true,
          align: 'center'
        },
        {
          title: '职位',
          key: 'positionName',
          width: 200,
          align: 'center'
        },
        {
          title: '姓名',
          key: 'name',
          minWidth: 120,
          align: 'center'
        },
        {
          title: '性别',
          key: 'gender',
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            let gender = params.row.sex
            let text = gender === 1 ? '男' : gender === 2 ? '女' : ''
            return h('span', text)
          }
        },
        {
          title: '联系电话',
          key: 'phone',
          width: 260,
          align: 'center'
        },
        {
          title: '权限',
          key: 'roleType',
          width: 260,
          align: 'center',
          render: (h, params) => {
            let roleType = params.row.roleType
            let text = roleType === 1 ? '超级管理员' : roleType === 2 ? '管理员' : '普通成员'
            return h('span', text)
          }
        },
        {
          title: '操作',
          slot: 'operation',
          width: 140
        }
      ],
      selectArr: [],
      pageNum: 1,
      pageSize: 20,
      createMemberVisible: false,
      memberPopupStatus: 1,
      createMember: {
        avatar: '',
        name: '',
        sex: '',
        phone: '',
        departmentId: 0,
        department: '',
        positionId: 0,
        position: '',
        permission: ''
      }
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.tableHeight = window.innerHeight - 340
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 340
      }
      this.getAuditList()
      this.getMemberList()
      getDepartmentList(0).then(res => {
        if (res.code === 0) {
          let list = res.data
          if (list.length === 0) {
            return
          }
          let arr = list.map(item => {
            return {
              value: item.id,
              label: item.name,
              loading: false,
              children: []
            }
          })
          this.departmentArr = arr
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 获取待审核成员列表
    getAuditList () {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        auditStatus: 1
      }
      if (this.name) {
        params.name = this.name
      }
      getMemberList(params).then(res => {
        if (res.code === 0) {
          this.auditCount = res.data.total
          this.auditData = res.data.list
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 获取成员列表
    getMemberList () {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        auditStatus: 2
      }
      if (this.permissionId) {
        params.type = this.permissionId
      }
      if (this.name) {
        params.name = this.name
      }
      getMemberList(params).then(res => {
        if (res.code === 0) {
          this.memberCount = res.data.total
          this.memberData = res.data.list.map(child => {
            let departmentName = ''
            let positionName = ''
            if (child.department && child.department.name) {
              departmentName = child.department.name
            }
            if (child.position && child.position.name) {
              positionName = child.position.name
            }
            child.departmentName = departmentName
            child.positionName = positionName
            return child
          })
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 点击tab item
    onClickTabItem (status) {
      this.status = status
      this.onListAllSelectCancel()
      this.pageNum = 1
      this.permissionId = 0
      this.name = ''
      if (this.status === 1) {
        this.getAuditList()
      } else {
        this.getMemberList()
      }
    },
    onSearch () {
      this.onListAllSelectCancel()
      this.pageNum = 1
      if (this.status === 1) {
        this.getAuditList()
      } else {
        this.getMemberList()
      }
    },
    // 全选取消
    onListAllSelectCancel () {
      this.selectArr = []
    },
    // 页数改变
    onPageChange (event) {
      this.onListAllSelectCancel()
      this.pageNum = event
      if (this.status === 1) {
        this.getAuditList()
      } else {
        this.getMemberList()
      }
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.onListAllSelectCancel()
      this.pageSize = event
      if (this.status === 1) {
        this.getAuditList()
      } else {
        this.getMemberList()
      }
    },
    // 选择改变
    onSelectionChange (selection) {
      this.selectArr = selection
    },
    // 获取部门列表
    getDepartmentList (item, callback) {
      item.loading = true
      getDepartmentList(item.value).then(res => {
        if (res.code === 0) {
          let list = res.data
          if (list.length === 0) {
            this.$delete(item, 'children')
            this.$delete(item, 'loading')
            return
          }
          item.children = list.map(item => {
            return {
              value: item.id,
              label: item.name,
              loading: false,
              children: []
            }
          })
          item.loading = false
          callback()
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 获取职位列表
    getPositionList (id) {
      return new Promise(((resolve, reject) => {
        let params = {
          departmentId: id,
          // pageNum: this.pageNum,
          // pageSize: this.pageSize
        }
        getPositionList(params).then(res => {
          if (res.code === 0) {
            this.positionArr = res.data.map(item => {
              return {
                id: item.id,
                name: item.name
              }
            })
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
          resolve()
        })
      }))
    },
    // 点击添加成员
    onClickCreate () {
      this.createMember = {
        avatar: '',
        name: '',
        sex: '',
        phone: '',
        departmentId: 0,
        department: '',
        positionId: 0,
        position: '',
        permission: ''
      }
      this.memberPopupStatus = 1
      this.createMemberVisible = true
    },
    onUserAddClose (status) {
      if (!status) {
        this.$refs.cascader.$children[0].$el.children[1].value = ''
        this.createMember = {
          avatar: '',
          name: '',
          sex: '',
          phone: '',
          departmentId: 0,
          department: '',
          positionId: 0,
          position: '',
          permission: ''
        }
      }
    },
    // 点击编辑成员
    async onClickEdit (row) {
      let res = await getMemberDetail(row.roleId)
      if (res.code === 0) {
        let data = res.data
        let departmentId = 0
        let positionId = 0
        let department = ''
        let position = ''
        if (data.department) {
          departmentId = data.department.id || 0
          department = data.department.name || ''
        }
        if (data.position) {
          positionId = data.position.id || 0
          position = data.position.position || ''
        }
        if (departmentId) {
          await this.getPositionList(departmentId)
        }
        this.createMember = {
          id: data.roleId,
          avatar: data.logo,
          name: data.name,
          sex: data.gender || 0,
          phone: data.phone,
          departmentId: departmentId || 0,
          department: department || '',
          positionId: positionId || 0,
          position: position || '',
          permission: data.roleType
        }

        this.$nextTick(() => {
          this.$refs.cascader.$children[0].$el.children[1].value = department
        })
        this.memberPopupStatus = 2
        this.createMemberVisible = true
      } else {
        if (res.msg) this.$Message.info(res.msg)
      }
    },
    // 上传头像
    uploadLogo (pathArr) {
      this.createMember.avatar = pathArr[0]
    },
    renderFormat (labels, selectedData) {
      const index = labels.length - 1
      return labels[index]
    },
    // 选择部门
    onChangeDepartment (value, selectedData) {
      console.log('onChangeDepartment !!!!!!!')
      let obj = selectedData[selectedData.length - 1]
      this.createMember.departmentId = obj.value
      this.createMember.department = obj.label
      this.createMember.positionId = ''
      this.createMember.position = ''
      this.getPositionList(this.createMember.departmentId)
    },
    // 选择职位
    onChangePosition (obj) {
      console.log('onChangePosition !!!!!!!')
      if (obj) {
        this.createMember.positionId = obj.value
        this.createMember.position = obj.label
      }
    },
    // 添加/编辑成员
    onClickCreateConfirm () {
      // if (!this.createMember.avatar) return this.$Message.info('请上传头像')
      if (!this.createMember.name) return this.$Message.info('请填写姓名')
      // if (!this.createMember.sex) return this.$Message.info('请选择性别')
      if (!this.createMember.phone) return this.$Message.info('请填写联系电话')
      // if (!this.createMember.departmentId) return this.$Message.info('请选择部门')
      // if (!this.createMember.positionId) return this.$Message.info('请选择职位')
      if (!this.createMember.permission) return this.$Message.info('请选择权限')
      let params = {
        logo: this.createMember.avatar,
        name: this.createMember.name,
        sex: this.createMember.sex,
        phone: this.createMember.phone,
        linkPhone: this.createMember.phone,
        departmentId: this.createMember.departmentId,
        department: this.createMember.department,
        positionId: this.createMember.positionId || 0,
        position: this.createMember.position,
        roleType: this.createMember.permission
      }
      if (this.memberPopupStatus === 1) {
        createMember(params).then(res => {
          if (res.code === 0) {
            this.$Message.info('添加成功')
            this.createMemberVisible = false
            if (this.status === 2) {
              this.getMemberList()
            }
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      } else {
        editMember(this.createMember.id, params).then(res => {
          if (res.code === 0) {
            this.$Message.info('编辑成功')
            this.createMemberVisible = false
            if (this.status === 2) {
              this.getMemberList()
            }
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      }
    },
    // 点击删除成员
    onClickDelete (row) {
      this.onDelete([row])
    },
    // 批量删除成员
    batchDelete () {
      this.onDelete(this.selectArr)
    },
    // 删除成员
    onDelete (list) {
      this.$Modal.confirm({
        title: '删除',
        content: '<p>成员信息删除后将无法恢复。<br>确认删除成员信息?</p>',
        onOk: () => {
          let idList = list.map(item => item.roleId)
          let params = {
            roleIdList: idList.join(',')
          }
          deleteMember(params).then(res => {
            if (res.code === 0) {
              this.$Message.info('删除成功!')
              this.onListAllSelectCancel()
              this.getMemberList()
            } else {
              if (res.msg) this.$Message.info(res.msg)
            }
          })
        }
      })
    },
    // 点击审核成员
    onClickAudit (row, status) {
      this.onAudit([row], status)
    },
    // 批量审核成员
    batchAudit (status) {
      this.onAudit(this.selectArr, status)
    },
    // 审核成员
    onAudit (list, status) {
      let idList = list.map(item => {
        return {
          id: item.roleId
        }
      })
      let params = {
        auditStatus: status === 1 ? 2 : 3,
        roleIdList: idList
      }
      auditMember(params).then(res => {
        if (res.code === 0) {
          this.onListAllSelectCancel()
          this.getAuditList()
        }
        if (res.msg) this.$Message.info(res.msg)
      })
    },
  },
}
</script>

<style lang='scss' scoped>
  .main {
    width: 100%;
    height: 100%;

    .title {
      height: 70px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #d8d8d8;

      h2 {
        font-size: 20px;
      }
    }

    .tab {
      display: flex;
      border-bottom: 1px solid #d8d8d8;

      li {
        padding: 10px;
        font-size: 16px;
        margin-left: 20px;
        cursor: pointer;

        &.current {
          padding-bottom: 9px;
          border-bottom: 1px solid #2d8cf0;
          color: #2d8cf0;
        }
      }
    }

    .content {
      height: 100%;
      padding: 20px;

      .topbar {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .topbar-left {
          display: flex;
        }
      }

      .list {
        border: 1px solid #e8eaec;
        padding-bottom: 10px;
        margin-top: 20px;

        .operation {
          display: flex;

          ::v-deep .ivu-btn {
            padding: 0;
            color: #2d8cf0;
          }
        }

        ::v-deep .ivu-table-cell {
          padding: 0;
        }
      }
    }
  }

  .createMember {
    padding: 10px 40px;
    position: relative;

    .createMember-li {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      span {
        font-size: 16px;
        width: 80px;
      }
    }

    .createMember-avatar {
      width: 320px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 20px;
      right: 0;

      img {
        width: 95px;
        height: 95px;
        border-radius: 50%;
        margin-bottom: 20px;
      }
    }

    .createMember-sub {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .createMember-btn {
        width: 80px;
      }
    }
  }
</style>
