<template>
  <div @click="handleClick">
    <input :accept="accept"
           :multiple="multiple"
           :webkitdirectory="webkitdirectory"
           @change="handleChange"
           class="input-style"
           ref="input"
           type="file">
    <slot></slot>
  </div>
</template>

<script>
import { getUploderObj } from "../../utils/ossupload";
import config from '../../config/constantconfig'
const uuidv1 = require('uuid/v1')
export default {
  props: {
    accept: {
      type: String,
      default: 'image/*'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    webkitdirectory: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    uploadpath: {
      type: String,
      default: 'test/'
    },
    callback: {
      type: Function
    },
  },
  methods: {
    handleClick () {
      if (this.disabled) return
      this.$refs.input.click()
    },
    handleChange (e) {
      const files = e.target.files
      if (!files) return
      this.upload(files)
      this.$refs.input.value = null
    },
    upload (files) {
      let filelist = Array.from(files)
      this.$emit('uploadstart')
      let resultArr = []
      filelist.forEach(item => {
        let uploader = this.newUploderObj(item)
        uploader.multipartUpload(null, this.callback).then(res => {
          console.log(res);
          if (res.url) {
            resultArr.push(res.url)
            let progressdata = {
              finshnum: resultArr.length,
              totalnum: filelist.length
            }
            this.$emit('uploadprogress', progressdata)
            if (resultArr.length === filelist.length) {
              this.$emit('uploadSuccess', resultArr)
            }
          }
        }, (err) => {
          console.log(err)
        })
      })
    },
    // 创建oss对象
    newUploderObj (file) {
      let uuid = uuidv1()
      let name = this.getFileSuffix(file.name)
      let fileType = this.$getFileType(file.name)
      let uploadPath
      switch (fileType) {
        case 'image':
          uploadPath = (config.isOnLine ? '' : 'image/') + config.projectName + this.uploadpath
          break;
        case 'video':
          uploadPath = config.projectName + this.uploadpath
          break;
        case 'audio':
          uploadPath = (config.isOnLine ? '' : 'audio/') + config.projectName + this.uploadpath
          break;
        case 'word':
          uploadPath = (config.isOnLine ? '' : 'file/') + config.projectName + this.uploadpath
          break;
        case 'ppt':
          uploadPath = (config.isOnLine ? '' : 'file/') + config.projectName + this.uploadpath
          break;
        case 'pdf':
          uploadPath = (config.isOnLine ? '' : 'file/') + config.projectName + this.uploadpath
          break;
        case 'excel':
          uploadPath = (config.isOnLine ? '' : 'file/') + config.projectName + this.uploadpath
          break;
        default:
          uploadPath = (config.isOnLine ? '' : 'file/') + config.projectName + this.uploadpath
          break;
      }
      let uploader = getUploderObj(fileType, uploadPath + uuid + name, file)
      return uploader
    },
    getFileSuffix (name) {
      let resultstr = ''
      let index = name.lastIndexOf('.')
      if (index > 0) {
        resultstr = name.substring(index)
      }
      return resultstr
    }
  }
}
</script>

<style lang="scss" scoped>
.input-style {
  display: none;
}
</style>
